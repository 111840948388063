import React, { useMemo, useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Dimensions, TouchableOpacity } from "react-native";
import { GlobalState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useDevices } from "@hooks";
import { Spacing, Colors } from "@constants";
import { Filter } from "@assets";
import { I18n } from "react-redux-i18n";
import { ModalTemplate } from '@atomic/templates';
import { Button } from "@atomic";
import { FiltersViewModel, FiltersType, FILTERTYPES } from "@modules";


// @ts-ignore
export const HomepageFilters: React.FC = () => {
    const dispatch = useDispatch();

    const height = Dimensions.get('window').height;
    const [isMobile] = useDevices();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const styles = useMemo(() => _styles(isMobile, height, brandTheme), [isMobile, height, brandTheme]);

    const filtersVM =  new FiltersViewModel();

    const { offerTypes, zones, posTypes, initialPosCards, filters } =  useSelector(
      (state: GlobalState) => ({
        offerTypes: state.filters.offerTypes,
        posTypes: state.filters.posTypes,
        zones: state.filters.zones,
        initialPosCards: state.filters.posCards || [],
        filters: state.filters.filters || [],
      })
    );

    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
    const [filtersCountModal, setFiltersCountModal] = useState<number>(0);
    const [filtersCountHomepage, setFiltersCountHomepage] = useState<number>(0);
    const [selectedFilters, setSelectedFilters] = useState<FiltersType[]>(filters);

    useEffect(() => {
      if(filters.length === 0 && selectedFilters.length !== 0){
        clearFilters();
        setFiltersCountHomepage(0);
      }
    }, [filters])

    const clearFilters = () => {
      setFiltersCountModal(0);
      setSelectedFilters([]);
    }
    const clearModalFilters = () => {
      setFiltersCountModal(0);
      setSelectedFilters([]);
    }

    const onClose = () => {
      if(filters.length !== selectedFilters.length) {
        setSelectedFilters(filters);
        setFiltersCountModal(filters.length)
      }
      
      setShowFiltersModal(false);
    }

    const isSelected = (type: string, value: any) => {
      
      const itemIndex = filtersVM.findFilterIndex({selectedFilters, type, value});
      return itemIndex !== -1;
    };

    const onTouchablePress = (type: string, value: any) => {
      const newValue = { type, value };

      const itemIndex = filtersVM.findFilterIndex({selectedFilters, type, value});

      if (itemIndex === -1) {
        const updatedArray = [...selectedFilters, newValue];
        setSelectedFilters(updatedArray);
        setFiltersCountModal(filtersCountModal + 1);

      } else {
        const updatedArray = [...selectedFilters];
        updatedArray.splice(itemIndex, 1);

        setSelectedFilters(updatedArray);
        setFiltersCountModal(filtersCountModal - 1);
      }
    };

  const applyFilters = async () => {
      
    filtersVM.filterCards({
      cards: initialPosCards,
      filters: selectedFilters,
      dispatch
    })
    setFiltersCountHomepage(filtersCountModal);
    setShowFiltersModal(false)

  }

    return (
      <View style={styles.container}>
        <Pressable style={styles.homepageFilter} onPress={() => setShowFiltersModal(true)}>
          <>
            <View style={styles.iconTitleContainer}>
              <View style={styles.iconContainer}>
                <Filter />
              </View>
              <Text style={styles.title}>{I18n.t("filters.title")} ({filtersCountHomepage})</Text>
            </View>
          </>
        </Pressable>
        <ModalTemplate
            isOpen={showFiltersModal}
            handleClose={onClose}
            closeOnClickOutside={true}
            isCenter={true}
            isFullScreen={true}
            paddingHorizontal={0}
            paddingVertical={0}
            style={styles.modal}
            isMobile={isMobile}
            bodyStyle={styles.modalBody}
        >
          <View style={styles.modalContainer}>
            <View style={styles.header}>
              <Text style={styles.modalTitle}>
                {I18n.t("filters.title")} ({filtersCountModal})
              </Text>
              <Text style={styles.unselectFilter} onPress={clearModalFilters}>
                {I18n.t("filters.unselectAll")}
              </Text>
            </View>
            {
            // offer types 
            }
            <View style={styles.filterSection}>
              <Text style={styles.filterTitle}>
                {I18n.t("filters.offerTypes")}
              </Text>
              <View style={styles.cardsContainer}>
                {offerTypes.map((eachOfferType: string, index: number) => (
                  <TouchableOpacity
                    key={`Filter_OffeZonesAndTypes_1_${index}`}
                    style={[
                      styles.filterCard,
                      isSelected(FILTERTYPES.OFFERTYPE, eachOfferType) && styles.filterCardSelected,
                    ]}
                    onPress={(): void => onTouchablePress(FILTERTYPES.OFFERTYPE, eachOfferType)}
                  >
                    <Text
                      style={[
                        styles.filterCardText,
                        isSelected(FILTERTYPES.OFFERTYPE, eachOfferType) && styles.filterCardTextSelected
                      ]}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {eachOfferType}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>

            </View>
            {
            // zones
            }
            <View style={styles.filterSection}>
              <Text style={styles.filterTitle}>
                {I18n.t("filters.zones")}
              </Text>
              <View style={styles.cardsContainer}>
                {zones.map((eachZone: {
                    id: string,
                    name: string,
                  }, 
                  index: number
                ) => (
                  <TouchableOpacity
                    key={`Filter_OffeZonesAndTypes_1_${index}`}
                    style={[
                      styles.filterCard,
                      isSelected(FILTERTYPES.ZONE, {id: eachZone.id, name: eachZone.name}) && styles.filterCardSelected,
                    ]}
                    onPress={(): void => onTouchablePress(FILTERTYPES.ZONE, {id: eachZone.id, name: eachZone.name})}
                  >
                    <Text
                      style={[
                        styles.filterCardText,
                        isSelected(FILTERTYPES.ZONE, {id: eachZone.id, name: eachZone.name}) && styles.filterCardTextSelected
                      ]}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {eachZone.name}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
            {
            // pos types
            }
            <View style={styles.filterSection}>
              <Text style={styles.filterTitle}>
                {I18n.t("filters.posTypes")}
              </Text>
              <View style={styles.cardsContainer}>
                {posTypes.map((eachPos: string, index: number) => (
                  <TouchableOpacity
                    key={`Filter_OffeZonesAndTypes_1_${index}`}
                    style={[
                      styles.filterCard,
                      isSelected(FILTERTYPES.POSTYPE, eachPos) && styles.filterCardSelected,
                    ]}
                    onPress={(): void => onTouchablePress(FILTERTYPES.POSTYPE, eachPos)}
                  >
                    <Text
                      style={[
                        styles.filterCardText,
                        isSelected(FILTERTYPES.POSTYPE, eachPos) && styles.filterCardTextSelected
                      ]}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {eachPos}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
            <Button
              onPress={applyFilters}
              label={`${I18n.t("filters.submitBtn")} (${filtersCountModal})`}
              styleButton={styles.submitBtn}
              styleLabel={styles.submitBtnText}
            />
          </View>
        </ModalTemplate>
        
      </View>
    );
  };

const _styles = (isMobile: boolean, height: number, brandTheme: any) => 
  StyleSheet.create({
    container: {
      flexDirection: "row",
      paddingLeft: Spacing.L,
      marginTop: isMobile ? Spacing.S : 0,
      marginBottom: Spacing.M,
      justifyContent: "flex-end",
      width: "100%",
    },
    iconTitleContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    homepageFilter: {
      flexDirection: "column",
    },
    iconContainer: {
      marginRight: 5,
    },
    title: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: isMobile ? 16 : 22
    },
    modalTitle: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 22
    },
    modal: {
      maxWidth: isMobile ? Dimensions.get('window').width : '414px',
      maxHeight: isMobile ? height : "95vh",
    },
    modalBody: {
      bottom: isMobile ? 0 : "auto"
    },
    modalContainer: {
      padding: Spacing.L,
      paddingBottom: isMobile ? 0 : Spacing.L,
      alignItems: "center",
      justifyContent: "center",
    },
    header: {
      height: 30,
      marginBottom: 30,
      width: '100%', 
      alignItems: 'center'
    },
    filterSection: {
      marginHorizontal: 20,
      marginBottom: 30,
      width: '100%',
    },
    filterTitle: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 13
    },
    unselectFilter: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 13,
      textDecorationLine: 'underline',
    },
    filterTitleSelected: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    cardsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    filterCard: {
      maxWidth: 200,
      height: 40,
      borderRadius: 8,
      borderWidth: 0.5,
      paddingVertical: 5,
      paddingHorizontal: 15,
      marginTop: 10,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterCardSelected: {
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      borderWidth: 0,
    },
    filterCardText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 16,
      maxWidth: 200,
    },
    filterCardTextSelected: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    submitBtn: {
      width: isMobile ? Dimensions.get('window').width : '200px',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: 60,
      borderRadius: isMobile ? 0 : 8,
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault
    },
    submitBtnText: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 22,
    },
    underline: {
      height: 3,
      backgroundColor: Colors.foodiBlack,
      width: "100%",
    },
  });
