import React from "react";
import { I18n } from "react-redux-i18n";

export const Filter: React.FC = () => (
<svg width="20px" height="20px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<title>{I18n.t("homepage.restaurantCard.filter")}</title>
    <g id="Icons/Filter_black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle id="Oval" stroke="#090909" stroke-width="1.5" transform="translate(11, 5) rotate(-360) translate(-11, -5)" cx="11" cy="5" r="2.25"></circle>
        <rect id="Rectangle" fill="#090909" transform="translate(5.5, 4.95) rotate(-540) translate(-5.5, -4.95)" x="2" y="4.2" width="7" height="1.5"></rect>
        <circle id="Oval" stroke="#090909" stroke-width="1.5" transform="translate(5, 11) rotate(-180) translate(-5, -11)" cx="5" cy="11" r="2.25"></circle>
        <rect id="Rectangle" fill="#090909" transform="translate(10.5, 10.95) rotate(-180) translate(-10.5, -10.95)" x="7" y="10.2" width="7" height="1.5"></rect>
    </g>
</svg>

);
