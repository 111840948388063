import { Dispatch, ReactNode } from "react";
import { I18n } from "react-redux-i18n";
import { FiltersActions, AtSiteTakeAwayType } from "@modules"
import { INode, IPos, OfferTemplateWithdrawalType } from "@foodi/core";
import { getNumericId } from "@utils";

type ZonesType = {
  id: string;
  name: string;
}

export type FiltersType = {
  type: string;
  value: string | ZonesType;
}

export enum FILTERTYPES {
  OFFERTYPE = "offerType",
  ZONE = "zone",
  POSTYPE = "posType"
}

export enum OFFERTYPES {
  EATIN = "Sur place",
  TAKEOUT = "À emporter"
}
export class FiltersViewModel {
  private eatInPosTypes = [AtSiteTakeAwayType.AT_SITE, AtSiteTakeAwayType.AT_SITE_TAKE_AWAY];
  private eatInOfferTemplateTypes = [
    OfferTemplateWithdrawalType.POS_AT_SITE,
    OfferTemplateWithdrawalType.POS_CLICK_SERVE,
    OfferTemplateWithdrawalType.TABLE_SERVICE,
  ];
  private takeOutPosTypes = [
    AtSiteTakeAwayType.TAKE_AWAY,
    AtSiteTakeAwayType.AT_SITE_TAKE_AWAY,
  ];
  private takeOutOfferTemplateTypes = [
    OfferTemplateWithdrawalType.CONNECTED_LOCKERS,
    OfferTemplateWithdrawalType.POS_TAKE_AWAY,
  ];

  setOfferType(posCard: IPos, typesSet: Set<string>){
    const eatInText = I18n.t("homepage.restaurantCard.eatIn");
    const takeOutText = I18n.t("homepage.restaurantCard.takeOut");
  
    let isEatIn = this.eatInPosTypes.includes(posCard.atSiteTakeAwayType as AtSiteTakeAwayType);
    let isTakeOut = this.takeOutPosTypes.includes(posCard.atSiteTakeAwayType as AtSiteTakeAwayType);
  
    posCard.activeOfferTemplates?.forEach((eachOffer) =>{
      if(!isEatIn){
        isEatIn = this.eatInOfferTemplateTypes.includes(eachOffer.withdrawalType);
      }
      if(!isTakeOut){
        isTakeOut = this.takeOutOfferTemplateTypes.includes(eachOffer.withdrawalType);
      }
    })
  
    if (isEatIn && isTakeOut){
      typesSet.add(eatInText);
      typesSet.add(takeOutText);
    } else {
      typesSet.add(isEatIn ? eatInText : takeOutText);
    }
  }

  setFiltersFromCards(
    cards: INode<IPos>[],
    dispatch: Dispatch<any>
  ) {
    const offerTypes: Set<string>= new Set([]);
    const zones: Set<string> = new Set([]);
    const posTypes: Set<string> = new Set([]);

    dispatch(FiltersActions.setInitFilters(cards));

    cards.forEach( ({node: eachCardNode}: INode<IPos>) => {
      // sets offer types
      this.setOfferType(eachCardNode, offerTypes);

      // sets zones
      const zoneId = getNumericId(eachCardNode?.zone?.id);
      zones.add(JSON.stringify({id: zoneId, name: eachCardNode?.zone?.name}));

      // sets pos types
      posTypes.add(eachCardNode.typeModel?.name);

      // sets preferred zones
      // TODO
    })
  
    dispatch(FiltersActions.setFilterState({
      offerTypes: Array.from(offerTypes),
      zones: Array.from(zones).map((eachZoneString) => JSON.parse(eachZoneString)),
      posTypes: Array.from(posTypes)
    }));
  }

  emptyFilter({
    cards,
    dispatch
  }: {
    cards: INode<IPos>[],
    dispatch: Dispatch<any>
  }){
    dispatch(FiltersActions.setFilteredPosCards(cards))

  }

  filterCards({
    cards = [],
    filters,
    dispatch
  }: {
    cards: INode<IPos>[],
    filters: FiltersType[],
    dispatch: Dispatch<any>
  }){

    if(filters.length === 0){
      this.emptyFilter({cards, dispatch});
      return;
    }

    let isEatIn = false;
    let isTakeOut = false;
    const offerTypeFilters: string[] = [];
    const zonesFilters: ZonesType[] = [];
    const posTypeFilters: string[] = [];

    filters.forEach(({type, value}: FiltersType) => {
      switch(type) {
        case FILTERTYPES.OFFERTYPE: {
          offerTypeFilters.push(value as string)
          if( value === OFFERTYPES.EATIN){
            isEatIn = true;
          } else {
            isTakeOut = true;
          }
          return;
        }
        case FILTERTYPES.POSTYPE: {
          posTypeFilters.push(value as string)
          return;
        }
        case FILTERTYPES.ZONE: {
          zonesFilters.push(value as ZonesType)
          return;
        }
      }
    })

    const filteredCards = cards.filter((eachCard: INode<IPos>) => {
      let isOfferTypeAvailable = false;
      let isZoneAvailable = false;
      let isPosTypeAvailable = false;

      // filter by Offer Type
      if(offerTypeFilters.length > 0){
        offerTypeFilters.forEach(eachOfferTypeFilter => {
          let posTypeToFilter = eachOfferTypeFilter === OFFERTYPES.EATIN ? this.eatInPosTypes : this.takeOutPosTypes;
          let OfferTemplateTypeToFilter = eachOfferTypeFilter === OFFERTYPES.EATIN ? this.eatInOfferTemplateTypes : this.takeOutOfferTemplateTypes;
          
          if(!isOfferTypeAvailable){
            isOfferTypeAvailable = posTypeToFilter.includes(eachCard.node?.atSiteTakeAwayType as AtSiteTakeAwayType) ||
              (
                eachCard.node?.activeOfferTemplates?.some((offer) =>
                  OfferTemplateTypeToFilter.includes(offer.withdrawalType)
              ) || 
              false
            );
          }
        })

      } else {
        isOfferTypeAvailable = true
      }

      // filter by Zones
      if(zonesFilters.length > 0){
        isZoneAvailable = zonesFilters.some(eachZoneFiler => eachZoneFiler.name === eachCard.node?.zone?.name);

      } else {
        isZoneAvailable = true
      }

      // filter by POS Type
      if(posTypeFilters.length > 0){
        isPosTypeAvailable = posTypeFilters.some(eachPosTypeFiltet => eachPosTypeFiltet === eachCard.node?.typeModel?.name)
      } else {
        isPosTypeAvailable = true
      }

      return (
        isOfferTypeAvailable &&
        isZoneAvailable &&
        isPosTypeAvailable
      )

    })
    dispatch(FiltersActions.setFilters(filters))

    dispatch(FiltersActions.setFilteredPosCards(filteredCards))
  }

  findFilterIndex({
    selectedFilters,
    type,
    value
  }: {
    selectedFilters: FiltersType[],
    type: string,
    value: string
  }){
    const newValue = { type, value };

    return selectedFilters.findIndex(
      filter => {
        return newValue.type === FILTERTYPES.ZONE ?
        (
          filter.type === newValue.type && 
          typeof filter.value === "object" && 
          // @ts-ignore
          filter.value.id === newValue.value.id
        ) :
        (filter.value === newValue.value && filter.type === newValue.type)
      }
    );
  }

}
