import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
} from "react-native";
import { Title13, Title16, Title18, Title22, Title30 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { I18n, setLocale } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { LocalLoader, SwitchItem } from "@atomic";
import { ILanguage, IOptinCode, IOptinOptions, IOptoutCode } from '@foodi/core';
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { OptinsViewModel, ProfileThunks } from "@modules/profile";
import { Back, FlagFR, FlagUK, Information } from "@assets";
import { AuthThunks, HomeScreens } from "@modules";
import { useDevices } from "@hooks";
import { SoldeTextInput } from "@atomic/molecules/SoldeTextInput";
import { debounce } from 'lodash';
import { NavigationProp } from "@react-navigation/native";
import { OptoutsViewModel } from '@modules/profile/viewmodels/OptoutsViewModel';

export const OPTINS_CODE: { [key in IOptinCode]: key } = {
  FOODI_MARKETING: "FOODI_MARKETING",
  FOODI_TOS: "FOODI_TOS",
  MAIL_FOODI_MARKETING: "MAIL_FOODI_MARKETING",
  MAIL_LOW_BALANCE: "MAIL_LOW_BALANCE",
  PUSH_FOODI_MARKETING: "PUSH_FOODI_MARKETING",
  PUSH_LOW_BALANCE: "PUSH_LOW_BALANCE",
  FOODI_ALLERGEN_DISCLAIMER: "FOODI_ALLERGEN_DISCLAIMER",
  AGE_VALIDATED: "AGE_VALIDATED"
};

export const OPTOUTS_CODE: { [key in IOptoutCode]: key } = {
    ORDER_RATING: "ORDER_RATING"
};

const allOptins = {
  FOODI_MARKETING: { enabled: false },
  FOODI_TOS: { enabled: true },
  MAIL_FOODI_MARKETING: { enabled: false },
  MAIL_LOW_BALANCE: { enabled: false },
  PUSH_FOODI_MARKETING: { enabled: false },
  PUSH_LOW_BALANCE: { enabled: false, options: { minBalance: 10 } },
  FOODI_ALLERGEN_DISCLAIMER: { enabled: false },
  AGE_VALIDATED: { enabled: false }
};

const allOptouts = {
    ORDER_RATING: { enabled: true },
    REMIND_ACTIVATE_NOTIFICATIONS: { enabled: true },
}

const LOW_BALANCE_MIN = 1;
const LOW_BALANCE_MAX = 100;

interface IProps {
  handleShowLeftContainer: () => void;
  navigation: NavigationProp<any>;
}

export const SettingsScreen: React.FC<IProps> = React.memo(
  ({ navigation, handleShowLeftContainer }) => {
    const [isMobile] = useDevices();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const styles = useMemo(() => _styles(isMobile, brandTheme), [isMobile, brandTheme]);

    const isOrderRatingEnabled = useSelector((state: GlobalState) => state.auth?.userInfo?.rating);
    const dispatch = useDispatch();
    const canUpdateOptins = useRef(false);
    const canUpdateOptouts = useRef(false);
    const canUpdateLanguage = useRef(false);
    const canUpdateVirtualTicket = useRef(false);
    const canUpdateLowBalanceOptin = useRef(true);

    const Title: React.FC<any> = isMobile ? Title22 : Title30;
    const Info: React.FC<any> = isMobile ? Title18 : Title22;

    const userOptins =
      useSelector((state: GlobalState) => state.auth?.userInfo?.userOptins) ??
      [];
    const userOptouts =
      useSelector((state: GlobalState) => state.auth?.userInfo?.userOptouts) ??
      [];
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const userVirtualTicket =
      useSelector(
        (state: GlobalState) => state.auth?.userInfo?.virtualTicket
      ) ?? false;

    const refillAllowed = useSelector(
          (state: GlobalState) => state.auth?.userInfo?.refillAllowed
      )

    const userMfaEnabled =
      useSelector((state: GlobalState) => state.auth?.userInfo?.mfaEnabled) ??
      false;

    const [isLoading, setLoading] = useState<boolean>(false);

    const [optins, setOptins] = useState<{ [key in IOptinCode]: { enabled: boolean, options?: IOptinOptions} }>(
      OptinsViewModel.initOptins(allOptins, userOptins)
    );
    const [optouts, setOptouts] = useState<{ [key in IOptoutCode]: { enabled: boolean } }>(
      OptoutsViewModel.initOptouts(allOptouts, userOptouts)
    );

    const [language, setLanguage] = useState<ILanguage>(
      userLanguage as ILanguage
    );
    const [virtualTicket, setVirtualTicket] = useState<boolean>(
      userVirtualTicket
    );

    const getMinBalance = () => {
      return (
        optins[OPTINS_CODE.PUSH_LOW_BALANCE].options?.minBalance
        ?? 0
      );
    }

    const valdidateLowBalanceValue = (value: number) => {
      canUpdateLowBalanceOptin.current = value >= LOW_BALANCE_MIN && value <= LOW_BALANCE_MAX;
    }

    const onChangeSwitch = (optinCode: IOptinCode, value: boolean, options?: IOptinOptions) => {
      setOptins({
        ...optins,
        [optinCode]: {
          enabled: value,
          options
        }
      });
    };

      const onChangeOptoutSwitch = (optoutCode: IOptoutCode, value: boolean) => {
          setOptouts({
              ...optouts,
              [optoutCode]: {
                  enabled: value
              }
          });
      };

    useEffect(() => {
      canUpdateLanguage.current && updateLanguage();
      canUpdateLanguage.current = true;
    }, [language]);

    useEffect(() => {
      canUpdateOptins.current && updateOptins();
      canUpdateOptins.current = true;
    }, [optins]);

    useEffect(() => {
      canUpdateOptouts.current && updateOptouts();
      canUpdateOptouts.current = true;
    }, [optouts]);

    useEffect(() => {
      canUpdateVirtualTicket.current && updateVirtualTicket();
      canUpdateVirtualTicket.current = true;
    }, [virtualTicket]);

    const updateLanguage = async () => {
      setLoading(true);
      await dispatch(ProfileThunks.updateLanguage({ language: language }));
      await dispatch(setLocale(language));
      await dispatch(AuthThunks.getUserInfo());
      setLoading(false);
    };

    const updateOptins = debounce(async () => {
      if (!canUpdateLowBalanceOptin.current) {
        updateOptins.cancel();
        return;
      }

      try {
        setLoading(true);
        await dispatch(
          ProfileThunks.updateOptins({
            optins: OptinsViewModel.getSelectedOptins(optins),
          })
        );
        await dispatch(AuthThunks.getUserInfo());
        setLoading(false);
      } catch (e) {
        canUpdateOptins.current = false;
        setOptins(OptinsViewModel.initOptins(allOptins, userOptins));
      }
    }, 600);

    const updateOptouts = debounce(async () => {
      try {
          setLoading(true);
          await dispatch(
              ProfileThunks.updateOptouts({
                  optouts: OptoutsViewModel.getSelectedOptouts(optouts),
              })
          );
          await dispatch(AuthThunks.getUserInfo());
      } catch (e) {
          canUpdateOptins.current = false;
          setOptouts(OptoutsViewModel.initOptouts(allOptouts, userOptouts));
      }
      finally {
          setLoading(false);
      }
    }, 600);

    const updateVirtualTicket = async () => {
      setLoading(true);
      await dispatch(
        ProfileThunks.updateVirtualTicket({
          virtualTicket,
        })
      );
      await dispatch(AuthThunks.getUserInfo());
      setLoading(false);
    };

    const updateMfa = async (mfaValue:boolean) => {
      setLoading(true);
      await dispatch(
        ProfileThunks.updateMfaConfig({
          mfaConfig: {
            enabled: mfaValue,
          },
        })
      );
      await dispatch(AuthThunks.getUserInfo());
      setLoading(false);
    };

    /* istanbul ignore next */
    const handleCookiesParameters = () => {
      // this is required because we need to force the reload of one trust functions to be able to display the cookies preferences on click
      (window as any)?.reloadOTBanner?.();
    };

    const handleHistory = () => {
      //@ts-ignore
      navigation?.navigate(HomeScreens.BALANCE_SCREEN);
    };

    return (
      <View>
        {isMobile && (
          <Pressable
            style={styles.goBackContainer}
            testID={TestIDs.profile.actions.goBack}
            onPress={handleShowLeftContainer}
          >
            <Back />
            <Text style={styles.goBackText}>{I18n.t("common.return")}</Text>
          </Pressable>
        )}
        <View
          testID={TestIDs.profile.views.settingView}
          style={styles.boxContainer}
        >
          {isMobile && (
            <Title30 isBold style={styles.marginTitle}>
              {I18n.t("profile.settings")}
            </Title30>
          )}
              <Title isBlack isBold>
            {I18n.t("profile.myNotifications")}
          </Title>
          <View style={[styles.block, { marginTop: Spacing.XL }]}>
            <View style={styles.notifInfo}>
                <Information />
                <Title16 style={styles.notifInfoText}>
                    {I18n.t("profile.mobileNotfiOnlyOnApp")}
                </Title16>
            </View>
            <Info isBold isBlack>
              {I18n.t("profile.tipsAndServices")}
            </Info>
            <View style={styles.inlineBlock}>
              <Title16 style={styles.infoNameDesc}>
                {I18n.t("profile.tipsAndServicesDesc")}
              </Title16>
            </View>
            <SwitchItem
              forwardTestID={TestIDs.profile.actions.notificationMobile}
              title={I18n.t("profile.mobileNotfi")}
              disabled={true}
              switchValue={optins[OPTINS_CODE.PUSH_FOODI_MARKETING].enabled}
              onValueChange={(value: boolean) => {
                onChangeSwitch(OPTINS_CODE.PUSH_FOODI_MARKETING, value);
              }}
            />
            <SwitchItem
              forwardTestID={TestIDs.profile.actions.email}
              title={I18n.t("profile.email")}
              switchValue={optins[OPTINS_CODE.MAIL_FOODI_MARKETING].enabled}
              onValueChange={(value: boolean) => {
                onChangeSwitch(OPTINS_CODE.MAIL_FOODI_MARKETING, value);
              }}
            />
          </View>
            {refillAllowed && <View style={[styles.block, { marginTop: Spacing.XL }]}>
                <Info isBold isBlack>
                    {I18n.t("profile.lowBalanceAlert")}
                </Info>
                <View style={styles.inlineBlock}>
                    <Title16 style={[styles.infoNameDesc, styles.noMargin]}>
                        {I18n.t("profile.lowBalanceAlertDesc")}
                    </Title16>
                    <SoldeTextInput
                        solde={getMinBalance()}
                        isInvalid={!canUpdateLowBalanceOptin.current}
                        validate={valdidateLowBalanceValue}
                        onChange={(value) => {
                            onChangeSwitch(OPTINS_CODE.PUSH_LOW_BALANCE, optins[OPTINS_CODE.PUSH_LOW_BALANCE].enabled, { minBalance: value })
                        }
                        }
                        editable={false}
                        style={styles.lowBalanceInput}
                    />
                </View>
                <Title13
                    style={[styles.infoNameDesc, styles.noMargin, !canUpdateLowBalanceOptin.current && styles.invalidText]}>
                    {I18n.t("profile.lowBalanceAlertDetails", { min: LOW_BALANCE_MIN, max: LOW_BALANCE_MAX })}
                </Title13>
                <SwitchItem
                    forwardTestID={TestIDs.profile.actions.notificationMobileBalance}
                    title={I18n.t("profile.mobileNotfi")}
                    switchValue={optins[OPTINS_CODE.PUSH_LOW_BALANCE].enabled}
                    disabled={true}
                    onValueChange={(value: boolean) => {
                        onChangeSwitch(
                            OPTINS_CODE.PUSH_LOW_BALANCE,
                            value,
                            allOptins[OPTINS_CODE.PUSH_LOW_BALANCE].options
                        );
                    }}
                />
            </View>}
                        { isOrderRatingEnabled && <View style={[styles.block, { marginTop: Spacing.XL }]}>
                <Info isBold isBlack>
                    {I18n.t("profile.orderRatingTitle")}
                </Info>
                <View style={styles.inlineBlock}>
                    <Title16 style={[styles.infoNameDesc, styles.noMargin]}>
                        {I18n.t("profile.orderRatingMessage")}
                    </Title16>
                </View>
                <SwitchItem
                    forwardTestID={TestIDs.profile.actions.orderRating}
                    title={I18n.t("profile.orderRating")}
                    switchValue={optouts[OPTOUTS_CODE.ORDER_RATING].enabled}
                    onValueChange={(value: boolean) => {
                        onChangeOptoutSwitch(OPTOUTS_CODE.ORDER_RATING, value);
                    }}
                />
              </View>
            }
        </View>

        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title isBlack isBold style={styles.ticketTitle}>{I18n.t("profile.mfa.title")}</Title>
          <View style={styles.iconContainer}>
            <Text style={styles.ticketSentence}>
              {I18n.t("profile.mfa.descriptionAuthorize")}
            </Text>
          </View>
          <SwitchItem
              forwardTestID={TestIDs.profile.actions.notificationMobile}
              title={I18n.t("profile.mfa.authorize")}
              switchValue={userMfaEnabled}
              onValueChange={(value: boolean) => {
                updateMfa(value);
              }}
            />
        </View>
        
        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title isBlack isBold style={styles.ticketTitle}>{I18n.t("profile.dematerializedTicket")}</Title>
          <View style={styles.iconContainer}>
            <View style={styles.iconMargin}>
              <Information />
            </View>
            <Text style={styles.ticketSentence}>
              {I18n.t("profile.dematerializedTicketSentence")}
            </Text>
          </View>
        </View>

        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title
            testID={TestIDs.profile.texts.settingsLanguageTitle}
            isBlack
            isBold
          >
            {I18n.t("profile.languages")}
          </Title>
          <View style={styles.languageBlock}>
            <TouchableOpacity
              style={[styles.language]}
              onPress={() => setLanguage(ILanguage.fr)}
            >
              <View style={styles.flag}>
                <FlagFR />
              </View>
              {language === ILanguage.fr && (
                <View style={styles.selectedLanguage} />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              testID={TestIDs.profile.actions.chooseLanguage}
              onPress={() => setLanguage(ILanguage.en)}
              style={[styles.language]}
            >
              <View style={styles.flag}>
                <FlagUK />
              </View>
              {language !== ILanguage.fr && (
                <View style={styles.selectedLanguage} />
              )}
            </TouchableOpacity>
          </View>
        </View>

        {isMobile && <View style={styles.separator} />}

        <View style={styles.boxContainer}>
          <Title isBlack isBold>
            {I18n.t("profile.cookies")}
          </Title>
          <div
            className="ot-sdk-show-settings-element"
            onMouseOver={handleCookiesParameters}
          >
            <Pressable>
              <Title18 color={Colors.blueLink} isBold style={styles.blueLink}>
                {I18n.t("profile.parameterize")}
              </Title18>
            </Pressable>
          </div>
        </View>

        {isLoading && (
          <View style={styles.loader}>
            <LocalLoader />
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isMobile: boolean, brandTheme: any) =>
  StyleSheet.create({
    boxContainer: {
      flex: 1,
      width: isMobile ? "90vw" : "100%",
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 20,
      marginBottom: 20,
    },
    block: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: isMobile ? 10 : 0,
    },
    inlineBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    lowBalanceInput: {
      margin: 0,
      marginHorizontal: Spacing.XS,
    },
    fullName: {
      fontWeight: "200",
      marginTop: Spacing.S,
    },
    accountInfo: {
      marginTop: Spacing.M,
      marginBottom: Spacing.L,
      justifyContent: "center",
      alignItems: "center",
    },
    iconDiv: {
      height: 120,
      width: 120,
      borderRadius: 60,
      borderWidth: 1,
      borderColor: Colors.foodiBlack,
      justifyContent: "center",
      alignItems: "center",
    },
    infoDiv: {
      flexDirection: "row",
      marginTop: Spacing.M,
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    infoNameDesc: {
      marginTop: Spacing.S,
      color: Colors.foodiBlack,
    },
    infoNameSwitch: {
      color: Colors.foodiBlack,
      marginRight: Spacing.M,
      fontFamily: "manrope-bold",
    },
    infoDetail: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
    },
    languageBlock: {
      flexDirection: "row",
      marginTop: isMobile ? 20 : 0,
    },
    language: {
      marginLeft: Spacing.M,
      borderColor: Colors.foodiBlack,
      justifyContent: "center",
      alignItems: "center",
      height: 46,
      width: 46,
      borderRadius: 23,
    },
    flag: {
      height: 40,
      width: 40,
      borderRadius: 20,
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedLanguage: {
      height: 46,
      width: 46,
      borderRadius: 23,
      borderWidth: 4,
      borderColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      position: "absolute",
    },
    blueLink: {
      textDecorationLine: "underline",
      marginTop: 3,
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
    },
    goBackText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 30,
      marginLeft: 7,
    },
    separator: {
      width: "90vw",
      height: 1,
      backgroundColor: Colors.disabledBackground,
      marginVertical: Spacing.L,
    },
    marginTitle: {
      marginBottom: 20,
    },
    loader: {
      height: "100%",
      width: "100%",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
    },
    noMargin: {
      margin: 0,
      marginTop: 0,
    },
    invalidText: {
      color: Colors.redError,
    },
    notifInfo: {
        flexDirection: "row",
        paddingLeft: isMobile ? 18 : 0,
        display: "flex",
        paddingBottom: Spacing.L
    },
    notifInfoText: {
        marginLeft: 6,
    },
    ticketSentence: {
      fontSize: 16,
      fontFamily: "manrope"
    },
    ticketTitle: {
      marginBottom: 12
    },
    iconContainer: {
      flexDirection: "row"
    },
    iconMargin: {
      marginRight: 10
    },
  });
