import { Colors } from "@constants";
import { Title16 } from "@stylesheets";
import style from "styled-components/native";

export const GoBackText = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
    margin-left: 7px;
`;

export const Row = style.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const YourOrder = style.Text<{ color?: string; hide?: boolean }>`
    color: ${({ color }) => (color ? color : Colors.foodiBlack)};
    font-family: manrope-bold;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

export const IconContainer = style.View`
    margin: 2px 0px 0px 10px;
`;

export const ModalContainer = style.View`
    padding: 10px 13px;
`;

export const Message = style(Title16)`
    margin: 5px 0px 0px 39px;
`;

export const CustomActivityIndicator = style.ActivityIndicator`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;

export const Action = style.Pressable<{
  marginTop?: number;
  justifyContent?: boolean;
  brandTheme: any;
}>`
    border-radius: 8px;
    padding: 5px 54px;
    background-color: ${({ brandTheme }) => brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault};
    align-self: center;
    justify-content: ${({ justifyContent }) =>
      justifyContent ? "center" : "flex-start"};
    text-align: center;
    margin-top: ${({ marginTop }) => marginTop}px;
`;
