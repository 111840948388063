import React, { useEffect, useMemo, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { Title13, Title22 } from "@stylesheets";
import { TestIDs } from "@utils";
import { IExternalService } from "@foodi/core";
import _ from "lodash";
import { ExternalLink } from "@assets";
import { Icon } from "@atomic";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { ServiceItem } from "./ServiceItem";

interface IProps {
  services: IExternalService[];
  isMobile: boolean;
}

export const ExternalServicesHome: React.FC<IProps> = React.memo(
  ({ services, isMobile }) => {
    const [isHover, setIsHover] = useState(false);
    const [isOpen, setIsOpen] = useState(isMobile);
    const wrapperRef = useRef(null);
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    
    const styles = useMemo(() => _styles(isHover || isOpen, isMobile, brandTheme), [
      isHover,
      isOpen,
      isMobile,
      brandTheme
    ]);

    const activeExternalServices = services.filter((service) => service.active && !!service.title && !!service.content);

    const TextLink: React.FC<any> = isMobile ? Title22 : Title13;

    const arrowIcon = isOpen
      ? "Accordeon_arrow_open_white"
      : "Accordeon_arrow_default_white";

    const openServicePage = (service: IExternalService) => {
      if (!_.isEmpty(service.content))
        window.open(service.content, "_blank")?.focus();
    };

    const checkIfClickOutside = (ref: any) => {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }
        window?.addEventListener("click", handleClickOutside);
        return () => {
          window?.removeEventListener("click", handleClickOutside);
        };
      }, [ref]);
    };

    !isMobile && checkIfClickOutside(wrapperRef);

    return (
      <View style={styles.bigContainer} ref={wrapperRef}>
        <Pressable
          testID={`${TestIDs.restaurantDetail.actions.externalServiceLink}`}
          onPress={() => setIsOpen(!isOpen)}
          // @ts-ignore
          onHoverIn={() => setIsHover(true)}
          onHoverOut={() => setIsHover(false)}
          style={styles.container}
          disabled={isMobile}
        >
          <ExternalLink fillColor={brandTheme.buttonActionColors?.textColor || Colors.foodiBlack}/>
          <TextLink
            testID={`${TestIDs.home.header.actions.externalServices}`}
            style={styles.textLink}
          >
            {I18n.t("homepage.utile")}
          </TextLink>
          {!isMobile && (
            <Icon name={arrowIcon} color={Colors.black} size={12} />
          )}
        </Pressable>
        {isOpen && (
          <View
            testID={`${TestIDs.home.header.views.externalServicesList}`}
            style={styles.serviceContainer}
          >
            {activeExternalServices.map((service, index) => {
              const _index = index + 1;
              return (
                <View>
                  <ServiceItem
                    service={service}
                    onPress={openServicePage}
                    isMobile={isMobile}
                  />
                  {_index < activeExternalServices.length && (
                    <View style={styles.separator} />
                  )}
                </View>
              );
            })}
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isActive: boolean, isMobile: boolean, brandTheme: any) =>
  StyleSheet.create({
    bigContainer: {
      ...(isMobile && {
        marginBottom: 16,
        margin: 16,
        width: "85%",
        borderRadius: 8,
        overflow: "hidden",
        borderWidth: 1,
        borderColor: Colors.darkGrey,
      }),
    },
    container: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      padding: 8,
      paddingLeft: 0,
      height: isMobile ? 40 : 30,
      borderRadius: isMobile ? 0 : 15,
      ...(isMobile && { width: "100%" }),
      ...(!isMobile && { marginRight: 30 }),
    },
    textLink: {
      fontFamily: isActive ? "manrope-bold" : "manrope",
      marginRight: 4,
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    serviceContainer: {
      width: isMobile ? "100%" : 180,
      backgroundColor: "white",
      position: isMobile ? "relative" : "absolute",
      top: isMobile ? 0 : 32,
      alignSelf: "center",
      borderRadius: 8,
      borderColor: Colors.lightWhite,
      borderWidth: 1,
    },
    separator: {
      height: 1,
      width: "100%",
      backgroundColor: Colors.middleGray,
    },
  });
