import { WarningOrange } from "@assets";
import { TestIDs } from "@utils";
import React from "react";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import {
  ModalContainer,
  Row,
  IconContainer,
  GoBackText,
  Action,
  YourOrder,
  CustomActivityIndicator,
  Message,
} from "./HoldingViewDeleteModal.style";
import { HoldingView } from "@foodi/core";
import { useDispatch, useSelector } from "react-redux";
import { HoldingThunks } from "@modules";
import { GlobalState } from "@redux";

interface IProps {
  handleClose?: (update?: boolean) => void;
  currentHoldingView?: HoldingView;
}

export const HoldingViewDeleteModal: React.FC<IProps> = React.memo(
  ({ handleClose, currentHoldingView }) => {
    const dispatch = useDispatch();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleDeleteHoldingView = async () => {
      if (currentHoldingView?.id) {
        setLoading(true);
        try {
          await dispatch(
            HoldingThunks.deleteUserHoldingView({
              id: currentHoldingView?.id,
            })
          );

          handleClose?.(true);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <ModalContainer testID={TestIDs.profile.views.holdingViewDeleteModal}>
        <Row>
          <IconContainer>
            <WarningOrange />
          </IconContainer>
          <GoBackText>{I18n.t("account.deleteModalTitle")}</GoBackText>
        </Row>
        <Message>{I18n.t("account.deleteModalMessage")}</Message>
        <Action
          testID={TestIDs.profile.actions.deleteHoldingView}
          marginTop={13}
          onPress={handleDeleteHoldingView}
          justifyContent={loading}
          brandTheme={brandTheme}
        >
          {loading ? (
            <>
              <CustomActivityIndicator
                testID={
                  TestIDs.profile.views.holdingViewDeleteModalActivityIndicator
                }
                color={
                  brandTheme.buttonActionColors?.textColor ||
                  Colors.foodiBlack
                }
              />
              <YourOrder 
                hide 
                color={
                  brandTheme.buttonActionColors?.textColor ||
                  Colors.foodiBlack
                }
              >
                {I18n.t("account.deleteModalCta")}
              </YourOrder>
            </>
          ) : (
            <YourOrder 
              color={
                brandTheme.buttonActionColors?.textColor ||
                Colors.foodiBlack
              }
            >
              {I18n.t("account.deleteModalCta")}
            </YourOrder>
          )}
        </Action>
      </ModalContainer>
    );
  }
);
