import React, { useState, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Title16, Title22 } from "@stylesheets";
import {
  HeartMealsViewModel,
  IOfferItem,
  OffersActions,
  OfferViewModel,
  PosType,
} from "@modules";
import { Colors } from "@constants";
import { TestIDs } from "@utils";
import { ProductsFamily } from "./ProductFamily";
import { useDispatch, useSelector } from "react-redux";
import { ArticleFamily, IMealHeartRule, OrderItem } from "@foodi/core";
import { Information } from "@assets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { GlobalState } from "@redux";
import { Holding } from "@foodi/core/lib/domain/entities/Holding";
import { IImportationTypes } from "@atomic";

interface IProps {
  offerItems: Record<string, IOfferItem[]>;
  selectedOfferId?: string;
  selectedActiveOfferId?: string;
  mealHeartRule?: IMealHeartRule;
  orderItems?: OrderItem[];
  holding: Holding;
  isBookingSelected?: boolean;
  selectedDay?: number;
  menuType?: IImportationTypes;
  hasContainers?: boolean;
}

const areEqual = (prevProps: IProps, nextProps: IProps) =>
  nextProps.isBookingSelected
    ? JSON.stringify(prevProps.offerItems) ===
      JSON.stringify(nextProps.offerItems)
    : prevProps.selectedActiveOfferId === nextProps.selectedActiveOfferId &&
      prevProps.selectedDay === nextProps.selectedDay &&
      prevProps.offerItems === nextProps.offerItems;

export const OfferProductsList: React.FC<IProps> = React.memo(
  ({
    offerItems,
    selectedOfferId,
    mealHeartRule,
    isBookingSelected,
    selectedActiveOfferId,
    holding,
    selectedDay,
    orderItems,
    menuType,
    hasContainers,
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const styles = useMemo(() => _styles(!!isMobile, brandTheme), [isMobile, brandTheme]);

    const [offerVM] = useState(new OfferViewModel());
    const updateOrders = (item: OrderItem) => {
      dispatch(OffersActions.addOrderItem(item));
    };

    const selectedPos = useSelector(
      (state: GlobalState) => state?.pointOfSale?.selectedPos
    );

    const heartMealsVM =
      mealHeartRule && new HeartMealsViewModel(mealHeartRule);
    const sameProductPerOrder = heartMealsVM?.getSameProductPerOrder() ?? 10;
    const isMenu = menuType !== undefined;

    const renderGroup = (
      [familyName, items]: [string, IOfferItem[]],
      index: number
    ) => {
      const realFamilyName = offerVM.getRealFamilyName(items);
      const isHeartFamily = heartMealsVM?.isHeartFamily(realFamilyName);
      const familyLabel = I18n.t(
        `restaurantDetail.productFamilies.${OfferViewModel.getLabelFromProductFamily(
          familyName
        )}`
      );
      const isAnyProducts =
        isMenu || items?.some((i: IOfferItem) => i?.inheritedFamily === ArticleFamily.FORMULA ? i?.isFormulaComplete : i?.quantityRemaining > 0);
      return (
        isAnyProducts && (
          <View
            testID={`${TestIDs.restaurantDetail.views.listProductGroup}${realFamilyName}`}
            style={styles.groupDiv}
            key={`${realFamilyName}${index}`}
          >
            <View style={styles.productNameDiv}>
              <Title22 style={styles.familyName}>{familyLabel}</Title22>
              {mealHeartRule && heartMealsVM?.isHeartFamily(realFamilyName) && (
                <View style={styles.unavailable}>
                  <View style={styles.unavailableBack} />
                  <Information backgroundColor={Colors.foodiDefault} />
                  <Title16 style={styles.unavailableText}>
                    {heartMealsVM.getMealsHeartRulesMessage()}
                  </Title16>
                </View>
              )}
            </View>
            <ProductsFamily
              isHeartFamily={!!isHeartFamily}
              familyItems={items}
              selectedActiveOfferId={selectedActiveOfferId}
              selectedOfferId={selectedOfferId}
              familyName={realFamilyName}
              updateOrders={updateOrders}
              sameProductPerOrder={sameProductPerOrder}
              isBookingSelected={isBookingSelected}
              selectedDay={selectedDay}
              orderItems={orderItems}
              menuType={menuType}
            />
          </View>
        )
      );
    };

    return (
      <View style={styles.container}>
        {offerItems &&
          offerVM.sortFamilyByName(offerItems, isMenu, holding).map(renderGroup)}
        {!!hasContainers && (
          <Title16 style={styles.containerInfo}>
            {I18n.t("restaurantDetail.container.infoMessage")}
          </Title16>
        )}
        <View style={styles.indication}>
          {isBookingSelected && (
            <>
              <Information />
              <Title16 style={styles.variations}>
                {I18n.t("restaurantDetail.variations")}
              </Title16>
            </>
          )}
        </View>
      </View>
    );
  },
  areEqual
);

const _styles = (isMobile: boolean, brandTheme: any) =>
  StyleSheet.create({
    container: {},
    groupDiv: {
      marginTop: 30,
      alignItems: "flex-start",
    },
    familyName: {
      color: Colors.foodiBlack,
      marginLeft: isMobile ? 18 : 2,
    },
    productNameDiv: {
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "flex-start",
      alignItems: isMobile ? "baseline" : "center",
      width: isMobile ? "100vw" : "",
    },
    unavailable: {
      marginLeft: 6,
      flexDirection: "row",
      padding: 4,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      overflow: "hidden",
    },
    unavailableText: {
      marginLeft: 6,
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    unavailableBack: {
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      opacity: 0.2,
      zIndex: -1,
    },
    containerInfo: {
      color: Colors.foodiBlack,
      paddingTop: 20,
      paddingLeft: isMobile ? 18 : 2,
      width: isMobile ? "100vw" : "auto",
    },
    indication: {
      flexDirection: "row",
      paddingTop: 20,
      justifyContent: "center",
      width: isMobile ? "65%" : "auto",
      margin: isMobile ? 10 : 0,
      padding: isMobile ? 10 : 0,
    },
    variations: {
      color: Colors.foodiBlack,
      paddingLeft: 6,
    },
  });
