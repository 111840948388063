import React from "react";

interface IProps {
  fillColor?: string;
}

export const ExternalLink: React.FC<IProps> = ({fillColor = "#000000"}) => (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>link_black_24dp (1)</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="HP-UI-D---Default-Copy-4"
        transform="translate(-584.000000, -14.000000)"
      >
        <g id="Services" transform="translate(582.000000, 14.094015)">
          <g
            id="link_black_24dp-(1)"
            transform="translate(19.000000, 16.905985) rotate(-50.000000) translate(-19.000000, -16.905985) translate(7.000000, 4.905985)"
          >
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M17,7 L13,7 L13,9 L17,9 C18.65,9 20,10.35 20,12 C20,13.65 18.65,15 17,15 L13,15 L13,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z M11,15 L7,15 C5.35,15 4,13.65 4,12 C4,10.35 5.35,9 7,9 L11,9 L11,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L11,17 L11,15 Z M8,11 L16,11 L16,13 L8,13 L8,11 Z"
              id="Shape"
              fill={fillColor}
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
