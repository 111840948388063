import { ReactNode } from "react";
import { createReducer } from '@redux';
import { Action } from "@redux/action";
import { INode, IPos } from "@foodi/core";
import { FiltersType } from "@modules";

export type ZonesType = {
  id: string;
  name: string;
}

export interface FiltersState {
  offerTypes: string[],
  zones: ZonesType[],
  posTypes: string[],
  preferredZones?: ZonesType[],
  posCards?: INode<IPos>[],
  filteredPosCards?: INode<IPos>[],
  filters?: FiltersType[];
}

enum TabsType {
  SEE_ALL = "SEE_ALL",
  EAT_IN = "EAT_IN",
  TAKE_OUT = "TAKE_OUT",
}

interface IRestaurantCardType {
  title: string;
  isSelected: boolean;
  type: TabsType;
  icon: ReactNode;
};

const initialState: FiltersState = {
  offerTypes: [],
  zones: [],
  posTypes: [],
  preferredZones: [],
  posCards: [],
  filteredPosCards: [],
  filters: [],
}

/*************  ACTIONS  ****************/

const actionTypes = {
  SET_PREFERRED_ZONES: 'SET_PREFERRED_ZONES',
  SET_FILTERS_STATE: 'SET_FILTERS_STATE',
  SET_FILTERED_POS_CARDS: 'SET_FILTERED_POS_CARDS',
  SET_FILTERS: 'SET_FILTERS',
  SET_INIT_FILTERS: 'SET_INIT_FILTERS',
}

const ActionCreators = {
  setFilterState: (filters: FiltersState): Action<FiltersState> => ({
    type: actionTypes.SET_FILTERS_STATE,
    payload: filters
  }),
  setPreferredZones: (preferredZones: ZonesType[]): Action<ZonesType[]> => ({
    type: actionTypes.SET_PREFERRED_ZONES,
    payload: preferredZones
  }),
  setFilteredPosCards: (filteredPosCards: INode<IPos>[]): Action<INode<IPos>[]> => ({
    type: actionTypes.SET_FILTERED_POS_CARDS,
    payload: filteredPosCards
  }),
  setFilters: (filters: FiltersType[]): Action<FiltersType[]> =>({
    type: actionTypes.SET_FILTERS,
    payload: filters
  }),
  setInitFilters: (posCards: INode<IPos>[]): Action< INode<IPos>[]> => ({
    type: actionTypes.SET_INIT_FILTERS,
    payload: posCards
  }),
}

/*************  REDUCER  ****************/

const reduction = {
  setFilterState: (
    state: FiltersState,
    { payload: filters}: Action<FiltersState>
  ): FiltersState => ({
    ...state,
    ...filters
  }),
  setPreferredZones: (
    state: FiltersState,
    { payload: preferredZones}: Action<ZonesType[]>
  ): FiltersState => ({
    ...state,
    preferredZones
  }),
  setFilteredPosCards: (
    state: FiltersState,
    { payload: filteredPosCards}: Action<INode<IPos>[]>
  ): FiltersState => ({
    ...state,
    filteredPosCards
  }),
  setFilters: (
    state: FiltersState,
    { payload: filters}: Action<FiltersType[]>
  ): FiltersState => ({
    ...state,
    filters
  }),
  setInitFilters: (
    state: FiltersState,
    { payload: posCards}: Action<any>
  ): FiltersState => ({
    ...state,
    posCards,
    filteredPosCards: posCards,
    filters: []
  }),
}

const reducer = createReducer(initialState,{
  [actionTypes.SET_FILTERS_STATE]: reduction.setFilterState,
  [actionTypes.SET_PREFERRED_ZONES]: reduction.setPreferredZones,
  [actionTypes.SET_FILTERED_POS_CARDS]: reduction.setFilteredPosCards,
  [actionTypes.SET_FILTERS]: reduction.setFilters,
  [actionTypes.SET_INIT_FILTERS]: reduction.setInitFilters,
});

export default reducer;

export {
  reducer as FiltersReducer,
  actionTypes as FiltersActionTypes,
  ActionCreators as FiltersActions,
};
