import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { RestaurantCardsList, HomepageFilters } from "@atomic";
import { RestaurantCardViewModel } from "@modules";
import { Container, InfoContainer, Text } from "./RestaurantCardFilters.style";
import { Information } from "@assets";
import { NavigationProp } from "@react-navigation/native";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { Spacing } from "@constants";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";

interface IProps {
  navigation?: NavigationProp<any>;
  affluence: PosAffluence[];
}
const RESTAURANT_CARD_WIDTH = 360;
const RESTAURANT_CARD_GAP = 30;
const RESTAURANT_CARD_MARGINS = 6;

export const RestaurantCardFilters: React.FC<IProps> = React.memo(
  ({ navigation, affluence }) => {
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const restaurantCardViewModel = new RestaurantCardViewModel();
    
    const filteredCardsList = useSelector(
      (state: GlobalState) => state.filters.filteredPosCards);

    const { holdingName } =
      useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

    const message = restaurantCardViewModel.getInfoMessage(0);

    return !!holdingName ? (
      <Container
        testID={
          TestIDs.home.restaurantCard.views.restaurantCardFiltersContainer
        }
        style={styles.container}
      >
        <HomepageFilters />
        {filteredCardsList?.length ? (
          <RestaurantCardsList
            restaurantCardsList={filteredCardsList}
            navigation={navigation}
            currentTab={0}
            affluence={affluence}
          />
        ) : (
          <InfoContainer style={styles.oneline}>
            <Information />
            <Text style={styles.fontResponsive}>{message}</Text>
          </InfoContainer>
        )}
      </Container>
    ) : null;
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: isMobile ? RESTAURANT_CARD_WIDTH : "100%",
      maxWidth: isMobile ? RESTAURANT_CARD_WIDTH : RESTAURANT_CARD_WIDTH * 3 + RESTAURANT_CARD_GAP * 2 + RESTAURANT_CARD_MARGINS,
    },
    transactionTabs: {
      width: isMobile ? "100%" : 652,
      marginBottom: isMobile ? 32 : 50,
      paddingHorizontal: isMobile ? 0 : "auto",
    },
    tabMargin: {
      paddingRight: Spacing.L,
    },
    oneline: {
      width: "auto",
    },
    fontResponsive: {
      fontSize: isMobile ? 11 : 16,
    },
  });
